import { createStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SwitchConditionalDisplay, { SwitchConditionalDisplayProps } from 'Components/Common/SwitchConditionalDisplay';
import { ReactElement } from 'react';

interface ChangeAllPlacementCardProps extends SwitchConditionalDisplayProps {}

const useClasses = makeStyles(({ spacing }) =>
	createStyles({
		root: {
			padding: spacing(3),
		},
	})
);

const ChangeAllPlacementCard = (props: ChangeAllPlacementCardProps): ReactElement => {
	const classes = useClasses();

	return (
		<Card className={classes.root}>
			<SwitchConditionalDisplay {...props}>{props.children}</SwitchConditionalDisplay>
		</Card>
	);
};

export default ChangeAllPlacementCard;
