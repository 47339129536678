import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import ChangeAllPlacementCardAngle from 'Components/Editor/Details/ChangeAllPlacements/ChangeAllPlacementCardAngle';
import ChangeAllPlacementCardArea from 'Components/Editor/Details/ChangeAllPlacements/ChangeAllPlacementCardArea';
import ChangeAllPlacementCardBrands from 'Components/Editor/Details/ChangeAllPlacements/ChangeAllPlacementCardBrands';
import ChangeAllPlacementCardPlacements from 'Components/Editor/Details/ChangeAllPlacements/ChangeAllPlacementCardPlacements';
import ChangeAllPlacementCardTime from 'Components/Editor/Details/ChangeAllPlacements/ChangeAllPlacementCardTime';
import ChangeAllPlacementModal from 'Components/Editor/Details/ChangeAllPlacements/ChangeAllPlacementModal';
import { UseVideoPlayerInstance } from 'Hooks/Editor/useVideoPlayerInstance';
import {
	DetectionAngleSpecification,
	DetectionAreaSpecification,
	DetectionTimeSpecification,
} from 'Models/Project/ProjectFilterSpecifications';
import { ReactElement, useState } from 'react';
import { PutChangeAllPlacementsRequest } from 'Utils/Http/Requests/Videos/Detections/PutChangeAllPlacementsRequest';

interface ChangeAllPlacementsDetailsProps {
	instance: UseVideoPlayerInstance;
}

const ChangeAllPlacementsDetails = (props: ChangeAllPlacementsDetailsProps): ReactElement => {
	const [selectedPlacementIds, setSelectedPlacementIds] = useState<number[] | null>(null);
	const [selectedBrandIds, setSelectedBrandIds] = useState<number[] | null>(null);
	const [selectedTexts, setSelectedTexts] = useState<number[] | null>(null);
	const [selectedLogos, setSelectedLogos] = useState<number[] | null>(null);
	const [angle, setAngle] = useState<DetectionAngleSpecification | null>(null);
	const [area, setArea] = useState<DetectionAreaSpecification | null>(null);
	const [time, setTime] = useState<DetectionTimeSpecification | null>(null);
	const [isChangeModalOpen, setIsChangeModalOpen] = useState<boolean>(false);

	const request: Omit<PutChangeAllPlacementsRequest, 'placementId'> = {
		placement: selectedPlacementIds !== null ? { placementIds: selectedPlacementIds } : null,
		brand: selectedBrandIds !== null ? { brandIds: selectedBrandIds } : null,
		logoOrText:
			selectedTexts !== null || selectedLogos !== null
				? {
						textIds: selectedTexts ?? [],
						logoIds: selectedLogos ?? [],
				  }
				: null,
		angle: angle,
		area: area,
		time: time,
		position: null, // TODO: Do the position.
	};

	const handleOpenModalButtonClicked = (): void => {
		setIsChangeModalOpen((prev) => !prev);
	};

	const handleChangeAllPlacementModalClose = (): void => {
		setIsChangeModalOpen(false);
	};

	const handleDetectionsChanged = (): void => {
		setIsChangeModalOpen(false);
		props.instance.clearDetections();
	};

	return (
		<>
			<Grid container={true} spacing={2}>
				<Grid item={true} xs={12} md={6} lg={4}>
					<ChangeAllPlacementCardPlacements
						placements={props.instance.placements}
						selectedIds={selectedPlacementIds}
						onSelectedIdsChanged={setSelectedPlacementIds}
					/>
				</Grid>
				<Grid item={true} xs={12} md={6} lg={4}>
					<ChangeAllPlacementCardBrands
						brands={props.instance.brands}
						selectedIds={selectedBrandIds}
						onSelectedIdsChanged={setSelectedBrandIds}
						selectedTexts={selectedTexts}
						onSelectedTextsChanged={setSelectedTexts}
						selectedLogos={selectedLogos}
						onSelectedLogosChanged={setSelectedLogos}
					/>
				</Grid>
				<Grid item={true} xs={12} md={6} lg={4}>
					<ChangeAllPlacementCardAngle angle={angle} onAngleChanged={setAngle} />
				</Grid>
				<Grid item={true} xs={12} md={6} lg={4}>
					<ChangeAllPlacementCardArea area={area} onAreaChanged={setArea} />
				</Grid>
				<Grid item={true} xs={12} md={6} lg={4}>
					<ChangeAllPlacementCardTime
						time={time}
						onTimeChanged={setTime}
						videoDuration={props.instance.videoNode?.duration ?? 0}
					/>
				</Grid>
			</Grid>
			<br />
			<CardActions>
				<Button variant="contained" onClick={handleOpenModalButtonClicked}>
					Open change all details modal
				</Button>
			</CardActions>
			<ChangeAllPlacementModal
				open={isChangeModalOpen}
				request={request}
				placements={props.instance.placements}
				videoId={props.instance.video?.id ?? 0}
				onClose={handleChangeAllPlacementModalClose}
				onDetectionChanged={handleDetectionsChanged}
			/>
		</>
	);
};

export default ChangeAllPlacementsDetails;
