import { Slider, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { isArray } from '@material-ui/data-grid';
import ChangeAllPlacementCard from 'Components/Editor/Details/ChangeAllPlacements/ChangeAllPlacementCard';
import { DetectionTimeSpecification } from 'Models/Project/ProjectFilterSpecifications';
import { ChangeEvent, ReactElement } from 'react';
import { padNumber } from 'Utils/conversions';

interface ChangeAllPlacementCardTimeProps {
	time: DetectionTimeSpecification | null;
	onTimeChanged: (time: DetectionTimeSpecification | null) => void;
	videoDuration: number;
}

const INITIAL_TIME_VALUE: DetectionTimeSpecification = { from: 0, to: 0 };

const ChangeAllPlacementCardTime = (props: ChangeAllPlacementCardTimeProps): ReactElement => {
	const fromValue = Math.round((props.time?.from ?? 0) * 1000);
	const toValue = Math.round((props.time?.to ?? 0) * 1000);
	const sliderValue = [fromValue, toValue];

	const getValueLabelFormat = (value: number): string => {
		const seconds = padNumber(Math.floor(value % 60), 2);
		const minutes = padNumber(Math.floor(value / 60) % 60, 2);
		const hours = Math.floor(value / 3600);
		return `${hours}:${minutes}:${seconds}`;
	};

	const startMark = {
		value: 0,
		label: getValueLabelFormat(0),
	};
	const middleMark = {
		value: Math.floor(props.videoDuration / 2),
		label: getValueLabelFormat(props.videoDuration / 2),
	};
	const endMark = {
		value: Math.floor(props.videoDuration),
		label: getValueLabelFormat(props.videoDuration),
	};
	const marks = [startMark, middleMark, endMark];

	const handlePlacementCardCheckedChanged = (checked: boolean): void => {
		if (checked) props.onTimeChanged(INITIAL_TIME_VALUE);
		else props.onTimeChanged(null);
	};

	const handleSliderChanged = (_: ChangeEvent<{}>, newValue: number | number[]): void => {
		if (isArray(newValue)) props.onTimeChanged({ from: newValue[0] / 1000, to: newValue[1] / 1000 });
		else console.error(`Cannot set angle. Given value '${newValue}' is invalid. Expected array with length 2.`);
	};

	return (
		<ChangeAllPlacementCard name="Select time" onCheckedChanged={handlePlacementCardCheckedChanged}>
			<FormControl fullWidth={true} margin="normal">
				<Typography id="range-slider" gutterBottom>
					Time
				</Typography>
				<Slider
					value={sliderValue}
					onChange={handleSliderChanged}
					defaultValue={0}
					marks={marks}
					valueLabelDisplay="auto"
					valueLabelFormat={getValueLabelFormat}
					min={0}
					max={props.videoDuration}
				/>
			</FormControl>
		</ChangeAllPlacementCard>
	);
};

export default ChangeAllPlacementCardTime;
