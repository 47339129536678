import { Checkbox, InputLabel, MenuItem, Select } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import ChangeAllPlacementCard from 'Components/Editor/Details/ChangeAllPlacements/ChangeAllPlacementCard';
import ChangeAllPlacementCardBrandLogoSelect from 'Components/Editor/Details/ChangeAllPlacements/ChangeAllPlacementCardBrandLogoSelect';
import ChangeAllPlacementCardBrandTextSelect from 'Components/Editor/Details/ChangeAllPlacements/ChangeAllPlacementCardBrandTextSelect';
import Brand from 'Models/Brands/Brand';
import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import { isNumberArray } from 'Utils/TypeGuards';

interface ChangeAllPlacementCardBrandsProps {
	brands: Brand[];
	selectedIds: number[] | null;
	onSelectedIdsChanged: (ids: number[] | null) => void;
	selectedTexts: number[] | null;
	onSelectedTextsChanged: (ids: number[] | null) => void;
	selectedLogos: number[] | null;
	onSelectedLogosChanged: (ids: number[] | null) => void;
}

const ChangeAllPlacementCardBrands = (props: ChangeAllPlacementCardBrandsProps): ReactElement => {
	const displayTextOrLogoSelect = props.selectedIds !== null && props.selectedIds.length === 1;
	const singleSelectedBrand =
		props.selectedIds?.length === 1
			? props.brands.find((x) => props.selectedIds !== null && x.id === props.selectedIds[0]) ?? null
			: null;

	const handleCheckedChanged = (checked: boolean): void => {
		if (checked) props.onSelectedIdsChanged([]);
		else props.onSelectedIdsChanged(null);

		props.onSelectedTextsChanged(null);
		props.onSelectedLogosChanged(null);
	};

	const handleBrandsSelectionChanged = ({ target }: React.ChangeEvent<{ value: unknown }>): void => {
		if (isNumberArray(target.value)) props.onSelectedIdsChanged(target.value);
		else console.error(`expected number array but instead got 'target.value'`);
		props.onSelectedTextsChanged(null);
		props.onSelectedLogosChanged(null);
	};

	const handleSelectedTextIdsChanged = (ids: number[]): void => {
		if (ids.length === 0) props.onSelectedTextsChanged(null);
		props.onSelectedTextsChanged(ids);
	};

	const handleSelectedLogoIdsChanged = (ids: number[]): void => {
		if (ids.length === 0) props.onSelectedLogosChanged(null);
		props.onSelectedLogosChanged(ids);
	};

	const renderValue = (selected: unknown): ReactNode => {
		if (!isNumberArray(selected)) return null;
		const brandNames = props.brands.filter((x) => selected.includes(x.id)).map((x) => x.name);
		return brandNames.join(', ');
	};

	return (
		<ChangeAllPlacementCard name={'Select brands'} onCheckedChanged={handleCheckedChanged}>
			<FormControl fullWidth={true} margin="normal">
				<InputLabel id="change-all-placement-brands-label">Brands</InputLabel>
				<Select
					labelId="change-all-placement-brands-label"
					value={props.selectedIds ?? []}
					onChange={handleBrandsSelectionChanged}
					multiple={true}
					renderValue={renderValue}
				>
					{props.brands.map((x) => (
						<MenuItem value={x.id} key={x.id}>
							<Checkbox checked={props.selectedIds?.includes(x.id)} />
							<ListItemText primary={x.name} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<ChangeAllPlacementCardBrandTextSelect
				displayContent={displayTextOrLogoSelect}
				brand={singleSelectedBrand}
				selectedTextIds={props.selectedTexts ?? []}
				onSelectedTextIdsChanged={handleSelectedTextIdsChanged}
			/>
			<br />
			<ChangeAllPlacementCardBrandLogoSelect
				brand={singleSelectedBrand}
				onSelectedLogoIdsChanged={handleSelectedLogoIdsChanged}
				selectedLogoIds={props.selectedLogos ?? []}
				displayContent={displayTextOrLogoSelect}
			/>
		</ChangeAllPlacementCard>
	);
};

export default ChangeAllPlacementCardBrands;
