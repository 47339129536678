import { Slider, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { isArray } from '@material-ui/data-grid';
import ChangeAllPlacementCard from 'Components/Editor/Details/ChangeAllPlacements/ChangeAllPlacementCard';
import { DetectionAreaSpecification } from 'Models/Project/ProjectFilterSpecifications';
import { ChangeEvent, ReactElement } from 'react';

interface ChangeAllPlacementCardAreaProps {
	area: DetectionAreaSpecification | null;
	onAreaChanged: (area: DetectionAreaSpecification | null) => void;
}

const INITIAL_AREA_VALUE: DetectionAreaSpecification = { from: 0, to: 0 };

const ChangeAllPlacementCardArea = (props: ChangeAllPlacementCardAreaProps): ReactElement => {
	const fromValue = Math.round((props.area?.from ?? 0) * 100);
	const toValue = Math.round((props.area?.to ?? 0) * 100);
	const sliderValue = [fromValue, toValue];

	const getValueLabelFormat = (value: number): string => {
		return `${value}%`;
	};

	const marks = Array.from(Array(9).keys()).map((x) => ({
		value: x * 20,
		label: `${x * 20}%`,
	}));

	const handlePlacementCardCheckedChanged = (checked: boolean): void => {
		if (checked) props.onAreaChanged(INITIAL_AREA_VALUE);
		else props.onAreaChanged(null);
	};

	const handleSliderChanged = (_: ChangeEvent<{}>, newValue: number | number[]): void => {
		if (isArray(newValue)) props.onAreaChanged({ from: newValue[0] / 100, to: newValue[1] / 100 });
		else console.error(`Cannot set angle. Given value '${newValue}' is invalid. Expected array with length 2.`);
	};

	return (
		<ChangeAllPlacementCard name="Select area" onCheckedChanged={handlePlacementCardCheckedChanged}>
			<FormControl fullWidth={true} margin="normal">
				<Typography id="range-slider" gutterBottom>
					Area
				</Typography>
				<Slider
					value={sliderValue}
					onChange={handleSliderChanged}
					defaultValue={0}
					marks={marks}
					valueLabelDisplay="auto"
					valueLabelFormat={getValueLabelFormat}
					min={0}
					max={100}
				/>
			</FormControl>
		</ChangeAllPlacementCard>
	);
};

export default ChangeAllPlacementCardArea;
