import { createStyles, Paper, Tab, Tabs } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TabContext, TabPanel } from '@material-ui/lab';
import ChangeAllPlacementsDetails from 'Components/Editor/Details/ChangeAllPlacements/ChangeAllPlacementsDetails';
import EditorDetectionDetails from 'Components/Editor/Details/EditorDetectionDetails';
import { UseVideoPlayerInstance, VideoPlayerDetailsAreaMode } from 'Hooks/Editor/useVideoPlayerInstance';
import { ReactElement } from 'react';

interface EditorDetailsProps {
	instance: UseVideoPlayerInstance;
}

const EditorDetails = (props: EditorDetailsProps): ReactElement => {
	const classes = useClasses();

	const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number): void => {
		switch (newValue) {
			case 0:
				props.instance.setDetailsAreaMode(VideoPlayerDetailsAreaMode.DETECTIONS);
				break;
			case 1:
				props.instance.setDetailsAreaMode(VideoPlayerDetailsAreaMode.CHANGE_ALL_PLACEMENTS);
				break;
			default:
				console.error('Dude what?!');
		}
	};

	return (
		<Paper className={classes.root}>
			<TabContext value={props.instance.detailsAreaMode.toString()}>
				<AppBar position="static">
					<Tabs value={props.instance.detailsAreaMode} onChange={handleTabChange}>
						<Tab label={'Selected detections '} value={VideoPlayerDetailsAreaMode.DETECTIONS} />
						<Tab label={'Change all placements'} value={VideoPlayerDetailsAreaMode.CHANGE_ALL_PLACEMENTS} />
					</Tabs>
				</AppBar>
				<TabPanel value={VideoPlayerDetailsAreaMode.DETECTIONS.toString()}>
					<EditorDetectionDetails instance={props.instance} />
				</TabPanel>
				<TabPanel value={VideoPlayerDetailsAreaMode.CHANGE_ALL_PLACEMENTS.toString()}>
					<ChangeAllPlacementsDetails instance={props.instance} />
				</TabPanel>
			</TabContext>
		</Paper>
	);
};

const useClasses = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			marginTop: theme.spacing(4),
		},
	})
);

export default EditorDetails;
