import { Slider, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { isArray } from '@material-ui/data-grid';
import ChangeAllPlacementCard from 'Components/Editor/Details/ChangeAllPlacements/ChangeAllPlacementCard';
import { DetectionAngleSpecification } from 'Models/Project/ProjectFilterSpecifications';
import { ChangeEvent, ReactElement } from 'react';

interface ChangeAllPlacementCardAngleProps {
	angle: DetectionAngleSpecification | null;
	onAngleChanged: (angle: DetectionAngleSpecification | null) => void;
}

const INITIAL_ANGLE_VALUE: DetectionAngleSpecification = { from: 0, to: 0 };

const ChangeAllPlacementCardAngle = (props: ChangeAllPlacementCardAngleProps): ReactElement => {
	const marks = Array.from(Array(9).keys()).map((x) => ({
		value: x * 45,
		label: `${x * 45}°`,
	}));

	const handlePlacementCardCheckedChanged = (checked: boolean): void => {
		if (checked) props.onAngleChanged(INITIAL_ANGLE_VALUE);
		else props.onAngleChanged(null);
	};

	const handleSliderChanged = (_: ChangeEvent<{}>, newValue: number | number[]): void => {
		if (isArray(newValue)) props.onAngleChanged({ from: newValue[0], to: newValue[1] });
		else console.error(`Cannot set angle. Given value '${newValue}' is invalid. Expected array with length 2.`);
	};

	return (
		<ChangeAllPlacementCard name="Select angle" onCheckedChanged={handlePlacementCardCheckedChanged}>
			<FormControl fullWidth={true} margin="normal">
				<Typography id="range-slider" gutterBottom>
					Angle
				</Typography>
				<Slider
					value={[props.angle?.from ?? 0, props.angle?.to ?? 0]}
					onChange={handleSliderChanged}
					defaultValue={0}
					marks={marks}
					valueLabelDisplay="auto"
					min={0}
					max={360}
				/>
			</FormControl>
		</ChangeAllPlacementCard>
	);
};

export default ChangeAllPlacementCardAngle;
